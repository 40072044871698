/*=========================================================================================
  File Name: i18n.js
  Description: i18n configuration file. Imports i18n data.
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import VueI18n from 'vue-i18n'
import i18nData from './i18nData'
import Cookies from "js-cookie";
Vue.use(VueI18n)
let localeValue = 'zh'
const testB = /(msg.venuscloud.vip)/i
if (Cookies.get("LANG")) {
  localeValue = Cookies.get("LANG")
} else {
  if (testB.test(window.location.host)) {
    localeValue = 'jp'
  }
  Cookies.set("LANG", localeValue)
}
export default new VueI18n({
  locale: Cookies.get("LANG") ? Cookies.get("LANG") : localeValue, // set default locale
  messages: i18nData,
})
