import Vue from 'vue'
import Router from 'vue-router'
// import Cookies from "js-cookie";
const Home = resolve => require(['@/views/index/Home.vue'], resolve)
Vue.use(Router)

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		}
	},
	routes: [
		{
			path: '/',
			type: 'home',
			name: 'home',
			// redirect: '/product',
			component: Home,
			}, {
			path: '/404',
			name: '404',
			component: () => import('./views/404.vue')
		}, {
			path: '*',
			redirect: '/404',
			hidden: true
		}
	],
})

// router.beforeEach((to, from, next) => {
// 	if (to.path === "/") {
// 		// 统一用户中心跳转 不需要验证登录
// 		// if (to.query.unitedLogin) {
// 		//   next()
// 		// } else
// 		// if (!Cookies.get("msg_loginIn")) {
// 		//   next('/login')
// 		// } else {
// 		//   next()
// 		// }
// 	} else {
// 		next()
// 	}

// });

router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg')
	if (appLoading) {
		appLoading.style.display = "none";
	}
})

export default router
