// en, zh, jp
export default {
  en: {
    port:"Port",
    "report": "Report",
    "Report receipt": "Report receipt",
    "Report receipt error": "Report receipt error",
    "Report type": "Report type",
    "Compliant domain": "Compliant domain",
    "Task name": "Task name",
    "Weekly report": "Weekly report",
    "Monthly report": "Monthly report",
    "The weekly report shall be calculated according to the calendar week and the report of last week will be generated and sent on Monday every week. Monthly report shall be calculated according to the calendar month and the report of last month will be generated and sent on the 1st every month.": "The weekly report shall be calculated according to the calendar week and the report of last week will be generated and sent on Monday every week. Monthly report shall be calculated according to the calendar month and the report of last month will be generated and sent on the 1st every month.",
    "Task name cannot be empty.": "Task name cannot be empty.",
    "Report sender cannot be empty.": "Report receipt cannot be empty.",
    "Please select the report type.": "Please select the report type.",
    "Report generation time": "Report generation time",
    "Report status": "Report status",
    "Email notification": "Email notification",
    "Generating": "Generating",
    "Generating successfully.": "Generating successfully.",
    "Generating failed.": "Generating failed.",
    "Report file has been deleted.": "Report file has been deleted.",
    "Waiting to send.": "Waiting to send.",
    "Sending successfully": "Sending successfully",
    "Sending failed": "Sending failed",
    "Download": "Download",
    "Project name": "Project name",
    "Strategy name": "Strategy name",
    "Create new matching condition.": "Create new matching condition.",
    "Add to matching condition list.": "Add to matching condition list.",
    "Matching condition list": "Matching condition list",
    "Content": "Content",
    "Not contain": "Not contain",
    "Source": "Source",
    "Rule": "Rule",
    "Create new condition": "Create new condition",
    "and": "And",
    "or": "Or",
    "mailbox name": "Mailbox name",
    "Matching condition list cannot exceed 10": "Matching condition list cannot exceed 10.",
    "the relationship between the matching conditions": "The relationship between the matching conditions",
    "Please select the source": "Please select the source.",
    "Submitting failed.There is a condition being edited.": "Submitting failed.There is a condition being edited!",
    "Please select the rule": "Please select the rule.",
    "spam type": "Spam type",
    "Please select the content": "Please select the content.",
    "Please input self-defining contents": "Please input self-defining contents",
    "Please add condition list": "Please add condition list.",
    "Email syntax is not compliant": "Email syntax is not compliant",
    "forgery Email title": "Forgery Email title",
    "Email content": "Email content",
    "Spam attachment": "Spam attachment",
    "comprehensive detection": "Comprehensive detection",
    "phishing": "Phishing",
    "URL reputation": "URL reputation",
    "Please input mailbox name": "Please input strategy name.",
    "Please select the relationship between the matching conditions": "Please select the relationship between the matching conditions.",
    Refresh: 'Refresh',
    return: 'Return',
    "Report task": "Report task",
    "Report view": "Report view",
    'Please try the following: check the network connection, check the proxy server and fire please': 'Please check the network connection, the proxy server, and the firewall in sequence',
    'Sorry the page you visited went wrong': 'An error occurred while visiting the page',
    test: 'Test',
    'The mailbox that receives the test email will receive a test email,please pay attention to check': 'Another test email will be sent to the specified email account.Please go to the email account to check whether you have received the email ',
    'Send a test email': 'Send a test email ',
    'Send a test message to the mailbox ': 'Send a test message to the email account ',
    'If the domain name has an SPF record, make sure to include the following records': 'If an SPF record is generated under the domain, check whether it contains the following records',
    'If the domain name has an TXT record, make sure to include the following records': 'If an TXT record is generated under the domain, check whether it contains the following records',
    'The minimum number of users is 0 and the maximum is 10,000. The number of users is recommended(such as 100, 1000).Users can customize the actual number of users.': 'The minimum number of users(0),the maximum number of users(600),the recommended number of users(such as 100 or 600),and the number of users that the user can customize ',
    'Number of users ': 'Number of users ',
    'Domain transmit and receive mode ': 'Domain transmit and receive mode ',
    "attachment sending encryption": "Attachment sending encryption",
    "create new attachment sending encryption": "Create new attachment sending encryption",
    "Edit attachment sending encryption": "Edit attachment sending encryption",
    "message security log": "Message security log",
    "email compliance log": "Email compliance log",
    "title": "Title",
    "virus type": "Virus type",
    "processing time": "Processing time",
    "Unprocessed": "Unprocessed",
    "send now": "Send now",
    "automatically cancel": "Automatically cancel",
    "auto-whitelist": "Auto-whitelist",
    "automatic approval": "automatic approval",
    "automatically rejected": "automatically rejected",
    "approve": "Approve",
    "reject": "Reject",
    "query": "Query",
    "clear": "Clear",
    "sender configuration": "Sender configuration",
    "sender matching condition": "Sender matching condition",
    "recipient configuration": "Recipient configuration",
    "recipient matching condition": "Recipient matching condition",
    "The input is not valid": "The input is not valid",
    "self-defining": "Self-defining",
    "Support multi-keyword and each keyword is separated by": "Support multi-keyword and each keyword is separated by ','. A single keyword is up to 255 characters and there is up to 20 keywords.",
    'A single keyword is up to 255 characters': "A single keyword cannot exceed 255 characters",
    'The number of keywords cannot be more than 20': "The number of keywords cannot be more than 20",
    "URL detection": "URL detection",
    server: 'Server',
    'Domain verification': "Domain verification",
    'Can not be empty': 'Cannot be empty',
    'Black and white list': 'Blacklist and whitelist',
    'Back step': "Previous",
    'Next step': 'Next',
    "Please enter": "Please input",
    'carry out': 'Finish',
    'New domain': 'New domain',
    'Incorrect information entered': 'The information you entered is incorrect',
    'Input can not be empty': 'Input cannot be empty',
    'incorrect mail domain': 'Incorrect mail domain',
    'Custom ip cannot be empty': 'You must enter a custom IP address',
    'Copy failed': 'Copy failed',
    'Copy successful': 'Copy successful',
    'Take over mail from users': 'Take over emails received by the user',
    'Take over user email': 'Take over emails sent by the user',
    copy: 'Copy',
    'Add the following MX records to the DNS configuration with the highest priority value': 'Add the following MX records to the DNS configuration of the highest priority',
    'Authentication method': 'Verification method',
    whitelist: 'Whitelist',
    recording: 'Record',
    blacklist: 'Blacklist',
    address: 'Address',
    Custom: 'Custom',
    'Engine contains': 'Engine contains',
    verification: 'Verification',
    'Suggested number of users such as': 'Recommended number of users, such as',
    'Create new blacklist': 'Create new blacklist',
    'Edit blacklist': 'Edit blacklist',
    'Create new white list': 'Create new white list',
    'Edit white list': 'Edit white list',
    'amount of users': 'Number of users',
    'Mode selection': 'Mode selection',
    'Quantity selection': 'Quantity selection',
    'Version selection': 'Version selection',
    'Fill everything @right': 'Fill in all the information after @',
    'Domain name': 'Fill in the domain name',
    'Domain configuration': 'Domain configuration',
    "New report": "New report",
    "Edit report": "Edit report",
    submit: 'Submit',
    confirm: 'Confirm',
    cancel: 'Cancel',
    deletion: "Delete",
    'confirm deletion': 'Confirm to delete',
    'Hair pattern': 'Receiving mode',
    Import: "Import",
    "Verify the password": "Verify the password",
    'edit email approval strategy': 'Edit email approval strategy',
    'add new email approval strategy': 'Add new email approval strategy',
    "password verification results": "Password verification results",
    "validation successful": "Validation successful",
    "validation failed": "Validation failed",
    successful: "Successful！",
    Export: "Export",
    verifying: "Verifying",
    'Send and receive mode': 'Send and receive mode',
    mode: 'Mode',
    version: 'Version',
    'article ': 'Count ',
    'Imported file cannot exceed 50 m': 'Imported file cannot exceed 50 m.',
    'X imports succeeded': 'X imports succeeded',
    'x saved': 'X saved',
    'X imports failed': 'X imports failed',
    'Imports failed': 'Imports failed',
    "Please input your password to verify the mail address": "Please input your password to verify the mail address.",
    'Per page': 'Per page',
    Records: 'Records',
    Total: 'Total',
    New: 'New',
    'No data': 'No data',
    Yes: 'Yes',
    No: 'No',
    Name: 'Name ',
    mailbox: 'Email account ',
    password: 'Password ',
    operating: 'Action',
    Verified: 'Verified ',
    'Waiting for verification': 'Waiting for verification',
    Unverified: 'Unverified',
    quickVerified: "Immediate verification",
    'Create domain': 'Create domain',
    time: 'Time',
    'View now': 'View now',
    'domain name': 'Domain name',
    area: 'Domain config',
    help: 'Help',
    User: 'User',
    'Data statistics': "Data statistics",
    'recent hour': 'Last hour',
    'recent day': 'Last day',
    'recent week': 'Last week',
    'recent month': 'Last month',
    seal: "Count",
    receive: "Received",
    send: "Sent",
    nonactivated: "Not activated",
    WelcomeText: 'Welcome back. Please log in to your account',
    emailText: "Email account",
    passwordText: "Password",
    emailError: "The email account format is incorrect",
    canempty: "Cannot be empty",
    quickLogin: "Log in now",
    'sign up': "Register",
    forget: "Forget  password",
    registerText: "Register now to try the service for free！",
    userText: 'Username',
    'All links under this user will be invalid, whether to delete': "All links under this user will be invalid, whether to delete?",
    codeText: 'Verification code',
    confirmPasswordText: 'Confirm the password',
    loginText: "An account has already logged in",
    userError1: "The user name length does not meet the length requirement",
    passwordError1: "The passwords you entered twice are inconsistent",
    passwordError2: "The password is too short",
    okText: "The password is successfully modified",
    submitText: "Submit",
    'Retrieve password': "Retrieve password",
    getCodeText: 'Get verification code',
    tabStatus: "Status",
    'Correct your password': 'Modify password',
    'MSG mail processing': "Process MSG emails",
    phoneError1: "You must enter a phone number",
    phoneError2: "The phone number you entered is incorrect",
    Overall: 'Overview',
    'Email Subject': "Email subject",
    'Process receiving time': "Process receiving time",
    'accessory name': "Attachment name",
    'Mail delivery time': "Email delivery time",
    'Sender mailbox': "Sender email account",
    'Recipient mailbox': "Recipient email account",
    action: "Action",
    'no data': 'No data',
    'old password': "Old password",
    'new password': "New password",
    'Confirm the new password': "Confirm the new password",
    'login out': "Log out",
    "sensitive words": "Sensitive words",
    'Edit user': "Edit user",
    'New user': "New user",
    'domain id': "Domain ID",
    edit: "Edit",
    yes: "Yes",
    no: "No",
    'Mail agreement':"Mail agreement",
    Release: 'Release',
    'Whether to discard': "Whether to discard",
    'throw away': 'Discard',
    Released: "Released",
    Discarded: "Discarded",
    "edit successfully": "edit successfully",
    "Add new successfully": "Add new successfully",
    'Whether to release': "Whether to release",
    'created okey': "Created successfully",
    'msg verification': 'MSG monitors whether the sent and received emails contain any virus or spam or have any information disclosure. If you want to enable the monitoring function, please verify your email account for login',
    'email verification': "Verification through email",
    "Incorrect format": 'Incorrect format',
    "Please select mode": "Please select mode",
    "Please select the server type": "Please select the server type",
    "Only one server of the same type can be created": "Only one server of the same type can be created",
    "Outbound server cannot be empty": "Outbound server cannot be empty",
    "Inboundserver cannot be empty": "Inboundserver cannot be empty",
    "Inbound server can only be one": "Inbound server can only be one",
    "Please select a server type": "Please select a server type",
    "please choose": "Please choose",
    "Server type ": "Server type",
    "Relay mode": "Relay mode",
    "Non-relay mode": "Non-relay mode",
    'Non-independent domain name': 'Non-independent domain name',
    "Outbound server": "Outbound server",
    "Inbound server": "Inbound server",
    "other": "Other",
    search: 'Search',
    "Mailbox type": "Mailbox type",
    "sending domain": "Sending domain",
    'sender/domain': "Sender/domain",
    'Sending domain cannot be empty': 'Sending domain cannot be empty',
    'Sender/domain cannot be empty': 'Sender/domain cannot be empty',
    "The description cannot be empty": "The description cannot be empty",
    'The email address is illegal': 'The email address is illegal',
    'The email address cannot be empty': 'The email address cannot be empty',
    'The object name cannot be empty': 'The object name cannot be empty',
    'release successfully': 'Release successfully',
    'send successfully': "Send successfully",
    'minutes': "Minutes",
    'I agree': 'I agree',
    'VCloud Service Level Agreement': 'VCloud Service Level Agreement',
    'VCloud service terms': 'VCloud service terms',
    'effective time': "Effective time",
    "action taken after the approval time has expired": 'action taken after the approval time has expired',
    "temporarily saved time": "Temporarily saved time",
    'apply': 'apply',
    'serial number': 'Serial number',
    'the approver cannot be empty': 'The approver cannot be empty',
    "email save temporarily": "Email save temporarily",
    "recipient/domain": "Recipient/domain",
    "strategy effective time": "Strategy effective time",
    "delete successfully": "Delete successfully",
    "sender": "Sender",
    "recipient": "Recipient",
    'domain': "Domain",
    'external domain': "External domain",
    'matching condition': 'Matching condition',
    'recipient matching': 'Recipient matching',
    'include': 'include',
    "Mail domain lists support only 1-100 mail domain objects": "Mail domain lists support only 1-100 mail domain objects",
    '64 bytes at most': '64 bytes at most',
    "Mail address lists support only 1-100 mail address objects": "Mail address lists support only 1-100 mail address objects",
    "hour": "Hour",
    "define file types": "Define file type",
    "edit attachment sending encryption": "Edit attachment sending encryption",
    "add new attachment sending encryption": "Add new attachment sending encryption",
    "add new email staging": "Add new email staging",
    "edit email staging": "Edit email staging",
    "recipient/recipient domain": "Recipient/recipient domain",
    "effective approval time": "Effective approval time",
    "release": "Release",
    "approver": "Approver",
    "bounce the email and notice the sender": "Bounce the email and notice the sender",
    "edit the email approval": "Edit the email approval",
    "add new email approval": "Add new email approval",
    "object name": "Object name",
    'object management': 'Object management',
    'security policy': 'Security policy',
    'email address object': 'Email address object',
    'Email attachment sending encryption': 'Email attachment sending encryption',
    'Email save temporarily': 'Email save temporarily',
    'Email approve': 'Email approve',
    'email domain object': 'Email domain object',
    'mail domain list': 'Mail domain list',
    'mail domain list cannot be empty': 'Mail domain list cannot be empty',
    'domain object': 'Domain object',
    'address object': 'Address object',
    'time object': 'Time object',
    'file type object': 'File type object',
    "member": "Memeber",
    "quote": "Quote",
    "add new email address object": "Add new email address object",
    "edit email address object": "Edit email address object",
    "email address list": "Email address list",
    "description": "Description",
    "support for entering multiple email addresses separated by": "Support for entering multiple email addresses separated by','",
    "add new email domain object": "Add new email domain object",
    "edit email domain object": "Edit email domain object",
    "start time": "Start time",
    "end time": "End time",
    "sender+recipient": "Sender+recipient",
    "type of attachment that needs to be encrypted": "Type of attachment that needs to be encrypted",
    "edit email attachment sending encryption": "Edit email attachment sending encryption",
    "add new email attachment sending encryption": "Add new email attachment sending encryption",
    "password recipient": "Password recipient",
    'mail domain': 'Mail domain',
    'mail object': 'Mail object',
    'Please select the sender message': "Please select the sender message",
    'The domain name you filled in is a non-independent domain name, please select other options':'The domain name you filled in is a non-independent domain name, please select other options'
  },
  zh: {
    port:"端口",
    "report": "报表",
    "Report receipt": "报表收件人",
    "Report receipt error": "报表收件人错误",
    "Report type": "报表类型",
    "Compliant domain": "适用的域",
    "Task name": "任务名称",
    "Weekly report": "周报表",
    "Monthly report": "月报表",
    "The weekly report shall be calculated according to the calendar week and the report of last week will be generated and sent on Monday every week. Monthly report shall be calculated according to the calendar month and the report of last month will be generated and sent on the 1st every month.": "周报表按照自然周统计，每周一发送上周的报表；月报表按照自然月统计，每月1号生成并发送上个月的报表",
    "Task name cannot be empty.": "任务名称不能为空",
    "Report sender cannot be empty.": "报表收件人不能为空",
    "Please select the report type.": " 请选择报表类型",
    "Report generation time": "报表生成时间",
    "Report status": "报表状态",
    "Email notification": "邮件通知",
    'Mail agreement':"邮件协议",
    "Generating": "生成中",
    "Generating successfully.": "生成成功",
    "Generating failed.": "生成失败",
    "Report file has been deleted.": "报表文件已删除",
    "Waiting to send.": "等待发送",
    "Sending successfully": "发送成功",
    "Sending failed": "发送失败",
    "Download": "下载",
    "Project name": "项目名称",
    "Strategy name": "策略名称",
    "Create new matching condition.": "新建匹配条件",
    "Add to matching condition list.": "添加到条件列表",
    "Matching condition list": "匹配条件列表",
    "Content": "内容",
    "Not contain": "不包含",
    "Source": "来源",
    "Rule": "规则",
    "Create new condition": "新建条件",
    "and": "与",
    "or": "或",
    "mailbox name": "邮箱名称",
    "Matching condition list cannot exceed 10": "条件列表不能超过十条",
    "the relationship between the matching conditions": "匹配条件之间的关系",
    "Please select the source": "请选择来源",
    "Submitting failed.There is a condition being edited.": "存在正在编辑的条件,提交失败！",
    "Please select the rule": "请选择规则",
    "spam type": "垃圾邮件类型",
    "Please select the content": "请选择内容",
    "Please input self-defining contents": "请输入自定义内容",
    "Please add condition list": "请添加条件列表",
    "Please input mailbox name": "请输入策略名称",
    "Email syntax is not compliant": "邮件语法不合规",
    "forgery Email title": "邮件头伪造",
    "Email content": "垃圾内容",
    "Spam attachment": "垃圾附件",
    "comprehensive detection": "综合检测",
    "phishing": "恶意钓鱼",
    "URL reputation": "url信誉低",
    "Please select the relationship between the matching conditions": "请选择匹配条件之间的关系",
    Refresh: '刷新',
    "Report task": "报表任务",
    "Report view": "报表任务",
    'return': '返回',
    'Please try the following: check the network connection, check the proxy server and fire please': '请试试以下方式：检查网络连接，检查代理服务器和防火请',
    'Sorry the page you visited went wrong': '对不起您访问的页面出错了',
    test: '测试',
    'The mailbox that receives the test email will receive a test email, please pay attention to check': '接收测试邮件的邮箱将会受到一封测试邮件，请注意查收',
    'Send a test email': '发送测试邮件',
    'Send a test message to the mailbox': '发送测试消息到邮箱',
    'If the domain name has an SPF record, make sure to include the following records': '如果域名有SPF记录，确认包含下面记录',
    'If the domain name has an TXT record, make sure to include the following records': '如果域名有TXT记录，确认包含下面记录',
    'The minimum number of users is 0 and the maximum is 10,000. The number of users is recommended (such as 100, 1000). Users can customize the actual number of users.': '用户数量最小值0人，最大值600人，用户数量建议（如100、600），用户可自定义填写实际的用户数量',
    'Number of users': '用户数量选择',
    'Domain transmit and receive mode': '域收发模式',
    'server': '服务器',
    "attachment sending encryption": "附件发送加密",
    "create new attachment sending encryption": "新建附件发件加密",
    "Edit attachment sending encryption": "编辑附件发件加密",
    "message security log": "信息安全日志",
    "email compliance log": "邮件合规日志",
    "title": "主题",
    "virus type": "病毒类型",
    "processing time": "处理时间",
    "approver": "审批人",
    "Unprocessed": "未处理",
    "send now": "立即发送",
    "release": "放行",
    "automatically cancel": "自动撤销",
    "auto-whitelist": "自动放行",
    "automatic approval": "自动通过",
    "automatically rejected": "自动拒绝",
    "approve": "通过",
    "reject": "拒绝",
    "query": "查询",
    "clear": "清空",
    "sender matching condition": "发件人匹配条件",
    "recipient matching condition": "收件人匹配条件",
    "The input is not valid": "输入内容不合法",
    "self-defining": "自定义",
    'A single keyword is up to 255 characters': "单个关键词最长255个字符",
    'The number of keywords cannot be more than 20': "关键字最多20条",
    "Support multi-keyword and each keyword is separated by": "支持多关键词， 以,分割，单个关键词最长255个字符， 最多20个关键词",
    "URL detection": "url检测",
    'Domain verification': "域名验证",
    'Can not be empty': '不能为空',
    "Back step": "上一步",
    'Next step': '下一步',
    "Please enter": "请输入",
    'carry out': '完成',
    'New domain': '新建域',
    'Incorrect information entered': '输入信息有误',
    'Input can not be empty': '输入不能为空',
    'incorrect mail domain': '邮件域不合法',
    'Custom ip cannot be empty': '自定义ip不能为空',
    'Copy failed': '复制失败',
    'Copy successful': '复制成功',
    'Take over mail from users': '接管用户的收邮件',
    'Take over user email': '接管用户的发邮件',
    copy: '复制',
    'Add the following MX records to the DNS configuration with the highest priority value': '将以下MX记录添加到具有最高优先级值的DNS配置中',
    'Authentication method': '验证方法',
    whitelist: '白名单',
    recording: '记录',
    blacklist: '黑名单',
    address: '地址',
    Custom: '用户自定义',
    'Engine contains': '引擎包含',
    verification: '验证',
    'Create new blacklist': '新建黑名单',
    'Edit blacklist': '编辑黑名单',
    'Create new white list': '新建白名单',
    'Edit white list': '编辑白名单',
    'Suggested number of users such as': '用户数量建议如',
    'amount of users': '用户数量',
    'Mode selection': '模式选择',
    'Quantity selection': '数量选择',
    'Version selection': '版本选择',
    'Fill everything @right': '填写@右边所有内容',
    'Domain name': '域名填写',
    'Domain configuration': '域配置',
    submit: '提交',
    "New report": "新建报表",
    "Edit report": "编辑报表",
    cancel: '取消',
    "deletion": "删除",
    'confirm deletion': '确认删除',
    'Hair pattern': '收模式',
    successful: "操作成功",
    'edit email approval strategy': '编辑邮件审批策略',
    'add new email approval strategy': '新建邮件审批策略',
    "password verification results": "密码验证结果",
    "validation successful": "验证成功",
    "validation failed": "验证失败",
    "Verify the password": "验证密码",
    Import: "导入",
    Export: "导出",
    verifying: "验证中",
    'Send and receive mode': '收发模式',
    mode: '模式',
    version: '版本',
    'article': '条',
    'Imported file cannot exceed 50 m': '导入文件不能超过50M。',
    'X imports succeeded': '导入成功X条',
    'x saved': '保留X条',
    "Please input your password to verify the mail address": "邮箱验证，请您录入密码!",
    'X imports failed': '导入失败X条',
    'Imports failed': '导入失败',
    'Per page': '每页',
    Records: '条记录',
    Total: '共',
    New: '新建',
    'No data': '暂无数据',
    Yes: '是',
    No: '否',
    'Name': '姓名',
    'mailbox': '邮箱',
    'password': '密码',
    'operating': '操作',
    'Verified': '已验证',
    quickVerified: "立即验证",
    'Waiting for verification': '等待验证',
    Unverified: '未验证',
    'Create domain': '创建域',
    'time': '时间',
    'View now': '立即查看',
    'domain name': '域名',
    area: '域配置',
    help: '帮助',
    User: '用户列表',
    "Data statistics": "数据统计",
    'recent hour': '最近1小时',
    'recent day': '最近1天',
    'recent week': '最近1周',
    'recent month': '最近1个月',
    seal: "封",
    receive: "收件",
    send: "发件",
    'Black and white list': "黑白名单",
    nonactivated: "未开通",
    WelcomeText: '欢迎回来,请登录您的帐户',
    emailText: "邮箱",
    passwordText: "密码",
    emailError: "电子邮箱格式有误",
    canempty: "不能为空",
    confirm: "确认",
    quick: "直ちに",
    quickLogin: "立即登录",
    'sign up': "注册",
    forget: "忘记密码",
    registerText: "立即注册享免费试用！",
    userText: '用户名',
    'All links under this user will be invalid, whether to delete': "该用户下所有链接将会失效，是否删除?",
    codeText: '验证码',
    confirmPasswordText: '确认密码',
    loginText: "有账号登录",
    userError1: "用户名过长或过短",
    passwordError1: "密码不一致",
    passwordError2: "密码过短",
    okText: "密码修改成功",
    submitText: "提交",
    "Retrieve password": "找回密码",
    getCodeText: '获取验证码',
    tabStatus: "状态",
    'Correct your password': '修改密码',
    'MSG mail processing': "MSG邮件处理",
    phoneError1: "手机号不能为空",
    phoneError2: "手机号有误",
    Overall: '整体概览',
    'Email Subject': "邮件主题",
    "Process receiving time": "流程接收时间",
    'accessory name': "附件名称",
    "Mail delivery time": "邮件发送时间",
    'Sender mailbox': "发件人邮箱",
    'Recipient mailbox': "收件人邮箱",
    "action": "动作",
    'no data': '暂无数据',
    "old password": "旧密码",
    "new password": "新密码",
    'Confirm the new password': "确认新密码",
    "login out": "退出登录",
    "sensitive words": "敏感词汇",
    edit: "编辑",
    "New user": "新建用户",
    "domain id": "域名id",
    "Edit user": "编辑用户",
    'yes': "是",
    "no": "否",
    "Release": '放行',
    "Whether to discard": "是否丢弃",
    "throw away": '丢弃',
    Discarded: "已丢弃",
    Released: "已放行",
    "edit successfully": "编辑成功",
    "Add new successfully": "新建成功",
    "Whether to release": "是否丢弃放行",
    "created okey": "新建成功",
    'msg verification': 'MSG可以监控邮件的收发，检测病毒、垃圾、信息泄露等邮件，如需开始监控邮件请验证登录邮箱',
    "email verification": "邮箱验证",
    "Incorrect format": '格式不正确',
    "Please select mode": "请选择模式",
    "Please select the server type": "请选择服务器类型",
    "Only one server of the same type can be created": "同类型服务器只能创建1个",
    "Outbound server cannot be empty": "发件服务器不能为空",
    "Inboundserver cannot be empty": "收件服务器不能为空",
    "Inbound server can only be one": "收件服务器数量只能为1",
    "Please select a server type": "请选择服务器类型",
    "please choose": "请选择",
    "Server type": "服务器类型",
    "Relay mode": "中继模式",
    "Non-relay mode": "非中继模式",
    'Non-independent domain name': '非独立域名',
    "Outbound server": "发件服务器",
    "Inbound server": "收件服务器",
    "other": "其他",
    search: '搜索',
    "Mailbox type": "邮箱类型",
    "sending domain": "发件域",
    'sender/domain': "发件人/域",
    'Sending domain cannot be empty': '发件域不能为空',
    'Sender/domain cannot be empty': '发件人/域不能为空',
    "The description cannot be empty": "描述不能为空",
    'The email address is illegal': '邮件地址不合法',
    'The email address cannot be empty': '邮件地址不能为空',
    'The object name cannot be empty': '对象名称不能为空',
    'release successfully': '放行成功',
    'send successfully': "发送成功",
    minutes: "分钟",
    'I agree': '同意',
    'VCloud Service Level Agreement': 'VCloud服务等级协议',
    'VCloud service terms': 'VCloud服务条款协议',
    'effective time': "生效时间",
    "action taken after the approval time has expired": '超过审批时间后的处理动作',
    "temporarily saved time": "暂存时间",
    apply: '启用',
    'serial number': '序号',
    'the approver cannot be empty': '审批人不能为空',
    "email save temporarily": "邮件暂存",
    "recipient/domain": "收件人/域",
    "strategy effective time": "策略生效时间",
    "delete successfully": "删除成功",
    "sender": "发件人",
    '64 bytes at most': '最长64字节',
    'Mail domain lists support only 1-100 mail domain objects': "邮件域列表只支持1-100邮件域对象",
    "Mail address lists support only 1-100 mail address objects": "邮件地址列表只支持1-100个邮件地址对象",
    "hour": "小时",
    "define file types": "自定义文件类型",
    "edit attachment sending encryption": "编辑附件加密",
    "add new attachment sending encryption": "新建附件加密",
    "add new email staging": "新建邮件暂存",
    "edit email staging": "编辑邮件暂存",
    "recipient/recipient domain": "收件人/收件域",
    "effective approval time": "有效审批时间",
    "bounce the email and notice the sender": "退信并通知发件人",
    "edit the email approval": "邮件编辑审批",
    "add new email approval": "新建邮件审批",
    "object name": "对象名称",
    'object management': '对象管理',
    'security policy': '安全策略',
    'email address object': '邮件地址对象',
    'email domain object': '邮件域对象',
    'Email attachment sending encryption': '邮件附件加密',
    'Email save temporarily': '邮件暂存',
    'Email approve': '邮件审批',
    'mail domain list': '邮件域列表',
    'mail domain list cannot be empty': '邮件域列表不能为空',
    'domain object': '域对象',
    'address object': '地址对象',
    'time object': '时间对象',
    'file type object': '文件类型对象',
    "member": "成员",
    "quote": "引用",
    "add new email address object": "新建邮件地址对象",
    "edit email address object": "编辑邮件地址对象",
    "email address list": "邮件地址列表",
    "description": "描述",
    "support for entering multiple email addresses separated by": "支持输入多个邮件地址，以 , 隔开",
    "add new email domain object": "新建邮件域对象",
    "edit email domain object": "编辑邮件域对象",
    "start time": "开始时间",
    "end time": "结束时间",
    "sender+recipient": "发件人+收件人",
    "recipient": "收件人",
    domain: "本域",
    'external domain': "外域",
    'sender configuration': '发件人配置',
    'matching condition': '发件人匹配条件',
    'recipient configuration': '收件人配置',
    'recipient matching': '收件人匹配条件',
    include: "包含",
    "type of attachment that needs to be encrypted": "需要加密的附件类型",
    "edit email attachment sending encryption": "编辑附件发送加密",
    "add new email attachment sending encryption": "新建附件发送加密",
    "password recipient": "密码接收人",
    'mail domain': '邮件域',
    'mail object': '邮件对象',
    'Please select the sender message': "请选择发件人信息",
    'The domain name you filled in is a non-independent domain name, please select other options':'您填写的域名为非独立域名，请选其他选项'
    
  },
  jp: {
    port:"ポート",
    'Mail agreement':"メール契約",
    report: 'レポート',
    "Report receipt": "レポートの受信者",
    "Report receipt error": "レポート送信先エラー",
    "Report type": "レポートタイプ",
    "Compliant domain": "適用のドメイン",
    "Task name": "タスクネーム",
    "Weekly report": "ウィークリーレポート",
    "Monthly report": "月間レポート",
    "The weekly report shall be calculated according to the calendar week and the report of last week will be generated and sent on Monday every week. Monthly report shall be calculated according to the calendar month and the report of last month will be generated and sent on the 1st every month.": "ウィークリーレポートは暦週に基づいて統計され、月曜日の度に先週のレポートを作成して送信します。月間レポートは暦月に基づいて統計され、1日の度に先月のレポートを作成して送信します。",
    "Task name cannot be empty.": "タスクネームは空にできません。",
    "Report sender cannot be empty.": "レポート送信元は空にできません。",
    "Please select the report type.": " レポートタイプを選択してください。",
    "Report generation time": "レポート作成時間",
    "Report status": "レポートステータス",
    "Email notification": "メールお知らせ",
    "Generating": "作成中",
    "Generating successfully.": "作成成功",
    "Generating failed.": "作成失敗",
    "Report file has been deleted.": "レポートファイルは削除されました。",
    "Waiting to send.": "送信待ち",
    "Sending successfully": "送信成功",
    "Sending failed": "送信失敗",
    "Download": "ダウンロード",
    "Project name": "項目ネーム",
    "Strategy name": "ストラテジーネーム",
    "Create new matching condition.": "マッチング条件を新規作成します。",
    "Add to matching condition list.": "条件リストに加えます。",
    "Matching condition list": "マッチング条件リスト",
    "Content": "内容",
    "Not contain": "含まない",
    "Source": "ソース",
    "Rule": "ルール",
    "Create new condition": "条件を新規作成します",
    "and": "と",
    "or": "或は",
    "mailbox name": "メールボックスネーム",
    "Matching condition list cannot exceed 10": "条件リストには十を超えてはいけません。",
    "the relationship between the matching conditions": "マッチング条件の間の関係",
    "Please select the source": "ソースを選択してください。",
    "Submitting failed.There is a condition being edited.": "編集中の条件があるので、コミットに失敗しました!",
    "Please select the rule": "ルールを選択してください。",
    "spam type": "スパムタイプ",
    "Please select the content": "内容を選択してください。",
    "Please input self-defining contents": "自己定義の内容を入力してください。",
    "Please add condition list": "条件リストを付け加えてください",
    "Email syntax is not compliant": "メールには不正な内容が存在しています。",
    "forgery Email title": "偽装のメールタイトル",
    "Email content": "スパムメールの詳細",
    "Spam attachment": "スパム添付ファイル",
    "comprehensive detection": "総合検知",
    "phishing": "フィッシング",
    "URL reputation": "URLレピュテーション",
    "Please input mailbox name": "ストラテジーネームを入力してください。",
    "Please select the relationship between the matching conditions": "マッチング条件の間の関係を選択してください。",
    Refresh: 'リフレッシュ',
    'return': 'リターン',
    "Report task": "レポートのタスク",
    "Report view": "レポートの表示",
    'Please try the following: check the network connection, check the proxy server and fire please': '下記の方法を試してください：ネットワーク接続をチェックすること、プロキシサーバーとファイアウォールをチェックすること',
    'Sorry the page you visited went wrong': '申し訳ございません。アクセスしているページにエラーがありました',
    test: 'テスト',
    'The mailbox that receives the test email will receive a test email, please pay attention to check': 'テストメールを受信するメールアドレス宛にテストメールを送りましたので、ご査収のほどお願いします',
    'Send a test email': 'テストメールを送ります',
    'Send a test message to the mailbox': 'メールボックス当てにテストメッセージを送ります',
    'If the domain name has an SPF record, make sure to include the following records': 'ドメイン名にSPF記録がある場合、下記の記録を含んでいることを確認してください',
    'If the domain name has an TXT record, make sure to include the following records': 'ドメイン名にTXT記録がある場合、下記の記録を含んでいることを確認してください',
    'The minimum number of users is 0 and the maximum is 10,000. The number of users is recommended (such as 100, 1000). Users can customize the actual number of users.': 'ユーザー数の最小値は0人、最大値は600人です。ユーザー数は100、600のような数値をお薦めします。ユーザーはカスタマイズした実際のユーザー数を記入すればよいです',
    'Number of users': 'ユーザー数を選択します',
    "attachment sending encryption": "添付ファイル送信暗号化",
    "create new attachment sending encryption": "添付ファイル送信暗号化を新規作成します",
    "Edit attachment sending encryption": "添付ファイル送信暗号化を編集します",
    "message security log": "情報セキュリティログ",
    "email compliance log": "メールコンプライアンスログ",
    "title": "タイトル",
    "virus type": "ウイルスタイプ",
    "processing time": "処理時間",
    "approver": "審査人",
    "Unprocessed": "未処理",
    "send now": "直ちに送信します",
    "automatically cancel": "自動的に取り消す",
    "auto-whitelist": "自動的にリリースする",
    "automatic approval": "自動的に審査完了する",
    "automatically rejected": "自動的に拒否する",
    "approve": "承認する",
    "reject": "拒否する",
    "query": "クエリ",
    "clear": "クリア",
    "sender configuration": "差出人コンフィグレーション",
    "sender matching condition": "差出人マッチ条件",
    "recipient configuration": "送信先コンフィグレーション",
    "recipient matching condition": "送信先マッチ条件",
    "The input is not valid": "入力した内容は不正なものを含んでいます。",
    "self-defining": "自己定義",
    "Support multi-keyword and each keyword is separated by": "複数のキーワード入力可能。「,」で仕切り、一つのキーワードは最多225個のキャラクタからなり、最大限20個のキーワード入力可能です。",
    'A single keyword is up to 255 characters': "一つのキーワードは最大255桁です",
    'The number of keywords cannot be more than 20': "キーワードは最大限20入力可能です",
    "URL detection": "URL検知",
    'Domain transmit and receive mode': 'ドメインの受発信モード',
    server: 'サーバー',
    "recipient/recipient domain": "送信先/送信先ドメイン",
    'Domain verification': "ドメイン検証",
    'Can not be empty': 'エンプティーにしてはいけません',
    'Black and white list': 'ブラック/ホワイトリスト',
    "Back step": "前へ",
    'Next step': '次へ',
    "Please enter": "入力",
    'carry out': '完了',
    'New domain': 'ドメインを新規作成します',
    'Incorrect information entered': '入力された情報に間違いがあります',
    'Input can not be empty': '入力はエンプティーにしてはいけません',
    'incorrect mail domain': '不正確なメールドメイン',
    'Custom ip cannot be empty': 'カスタムipはエンプティーにしてはいけません',
    'Copy failed': 'コピーするのに失敗しました',
    'Copy successful': 'コピーするのに成功しました',
    'Take over mail from users': 'ユーザーのメール受信をテークオーバーします',
    'Take over user email': 'ユーザーのメール発信をテークオーバーします',
    copy: 'コピー',
    'Add the following MX records to the DNS configuration with the highest priority value': '下記のMX記録を最高プライオリティバリューのDNSコンフィグレーションに追加します',
    'Authentication method': '検証方法',
    whitelist: 'ホワイトリスト',
    recording: '記録',
    blacklist: 'ブラックリスト',
    address: 'アドレス',
    Custom: 'カスタム',
    'Engine contains': 'エンジンは下記のことを含んでいます',
    verification: '検証',
    'Create new blacklist': 'ブラックリストを新規作成します',
    'Edit blacklist': 'ブラックリストを編集します',
    'Create new white list': 'ホワイトリストを新規作成します',
    'Edit white list': 'ホワイトリストを編集します',
    'Suggested number of users such as': '下記のようなユーザー数をお薦めします',
    'amount of users': 'ユーザー数',
    'Mode selection': 'モード選択',
    'Quantity selection': '数量選択',
    'Version selection': 'バージョン選択',
    'Fill everything @right': '@右側のすべての内容を記入してください',
    'Domain name': 'ドメイン名',
    'Domain configuration': 'ドメインコンフィグレーション',
    submit: '提出',
    cancel: 'キャンセル',
    "New report": "新しいレポート",
    "Edit report": "レポートを編集",
    "deletion": "削除",
    'confirm deletion': '削除を確認します',
    'Hair pattern': '受信モード',
    successful: "完了しました",
    Import: "インポート",
    "Verify the password": "パスワードを認証します",
    'edit email approval strategy': 'メール審査ストラテジーを編集します',
    'add new email approval strategy': 'メール審査ストラテジーを新規作成します',
    "password verification results": "パスワード認証結果",
    "validation successful": "認証完了しました",
    "validation failed": "認証できません",
    Export: "エクスポート",
    verifying: "認証中",
    'Send and receive mode': '受発信モード',
    mode: 'モード',
    version: 'バージョン',
    'article': '件',

    'Imported file cannot exceed 50 m': 'インポートされるファイルの最大限は50Ｍです。',
    'X imports succeeded': 'インポート完了X件',
    'x saved': '保存X件',

    'X imports failed': 'インポート失敗X件',
    "Please input your password to verify the mail address": 'メー,ルアドレスを認証するために、パスワードを入力してください。',
    'Imports failed': 'インポート失敗',
    'Per page': 'ページごと',
    Records: '件の記録',
    Total: 'トータル',
    New: '新規作成',
    'No data': 'データがありません',
    Yes: 'はい',
    No: 'いいえ',
    'Name': '名前',
    'mailbox': 'メールボックス',
    'password': 'パスワード',
    'operating': '操作',
    'Verified': '検証済み',
    'Waiting for verification': '検証待ち',
    Unverified: '未検証',
    quickVerified: "直ちに検証",
    'Create domain': 'ドメインを新規作成します',
    'time': '時間',
    'View now': '直ちに確認します',
    'domain name': 'ドメイン名',
    area: 'ドメイン設定',
    help: 'ヘルプ',
    User: 'ユーザー',
    "Data statistics": "データ統計",
    'recent hour': '過去の1時間',
    'recent day': '過去の1日',
    'recent week': '過去の1週間',
    'recent month': '過去の1ヶ月',
    seal: "通",
    receive: "受信",
    send: "発信",
    nonactivated: "アクティブ化していません",
    WelcomeText: 'ようこそ、アカウントをログインしてください',
    emailText: "メールアドレス",
    passwordText: "パスワード",
    emailError: "メール形式エラー",
    canempty: "エンプティーにしてはいけません",
    confirm: "確認",
    quickLogin: "直ちにログインします",
    'sign up': "アカウントを作成",
    forget: "パスワードを忘れました",
    registerText: "直ちに登録して無料で試用します。",
    userText: 'ユーザー名',
    'All links under this user will be invalid, whether to delete': "このユーザーのすべてのリンクは無効になります,削除しますか?",
    codeText: '検証コード',
    confirmPasswordText: 'パスワードを確認します',
    loginText: "ログインしているアカウントがあります",
    userError1: "ユーザー名が長すぎか短すぎです",
    passwordError1: "パスワードが一致していません",
    passwordError2: "パスワードが短すぎです",
    okText: "パスワードの変更に成功しました",
    submitText: "提出",
    tabStatus: "ステータス",
    "Retrieve password": "パスワードを取り戻します",
    getCodeText: '検証コードを取得します',
    'Correct your password': 'パスワードを変更します',
    'MSG mail processing': "MSGメール処理",
    phoneError1: "携帯番号をエンプティーにしてはいけません",
    phoneError2: "携帯番号に間違いがあります",
    Overall: '全体概要',
    'Email Subject': "メールの主題",
    "Process receiving time": "プロセス受信時間",
    'accessory name': "添付ファイル名",
    "Mail delivery time": "メール送信時間",
    'Sender mailbox': "送信者のメール",
    'Recipient mailbox': "受信者のメール",
    "action": "アクション",
    'no data': 'データ無し',
    "old password": "旧いパスワード",
    "new password": "新しいパスワード",
    'Confirm the new password': "新しいパスワードを確認します",
    "login out": "ログアウトします",
    "sensitive words": "デリケートな言葉",
    edit: "編集",
    "New user": "ユーザーを新規作成します",
    "domain id": "ドメイン名id",
    "Edit user": "ユーザーを編集します",
    'yes': "はい",
    "no": "いいえ",
    "Release": 'リリース',
    "Whether to discard": "廃棄しますか",
    "throw away": '廃棄します',
    Discarded: "捨てた",
    Released: "リリース済み",
    "edit successfully": "変更完了",
    "Add new successfully": "新規作成完了",
    "Whether to release": "リリースしますか",
    "created okey": "新規作成するのに成功しました",
    'msg verification': 'MSGは、メールの送受信を監視し、ウイルス、スパム、情報漏えいなどのメールを検出できます。メールの監視を開始する場合は、ログインメールボックスを確認してください。',
    "email verification": "メール確認",
    "Incorrect format": 'フォーマットが間違っています',
    "Please select mode": "モードを選択してください",
    "Please select the server type": "サーバーの種類を選択してください",
    "Only one server of the same type can be created": "同じタイプのサーバーを1つだけ作成できます",
    "Outbound server cannot be empty": "送信サーバーは空にできません",
    "Inboundserver cannot be empty": "受信サーバーを空にすることはできません",
    "Inbound server can only be one": "1つの受信サーバーのみ",
    "Please select a server type": "サーバーの種類を選択してください",
    "please choose": "選択してください",
    "Server type ": "サーバータイプ",
    "Relay mode": "リレーモード",
    "Non-relay mode": "非リレーモード",
    'Non-independent domain name': '独立していないドメイン名',
    "Outbound server": "送信サーバー",
    "Inbound server": "受信サーバー",
    "other": "その他の",
    "Server type": "サーバータイプ",
    search: '検索',
    "Mailbox type": "メールボックスの種類",
    "sending domain": "送信ドメイン",
    'sender/domain': "差出人／ドメイン",
    'Sending domain cannot be empty': '送信ドメインは空きにできません',
    'Sender/domain cannot be empty': '差出人/ドメインは空きにできません',
    "The description cannot be empty": "記述内容は空にできません",
    'The email address is illegal': '不法なメールアドレスです',
    'The email address cannot be empty': 'メールアドレスは空にできません',
    'The object name cannot be empty': 'オブジェクトネームは空にできません',
    'release successfully': 'リリースを完了しました',
    'send successfully': "発信を完了しました",
    minutes: "分",
    'VCloud Service Level Agreement': 'VCloudサービス水準合意',
    'VCloud service terms': 'VCloudセキュリティサービス利用規約',
    'I agree': '同意する',
    'effective time': "発効時間",
    "action taken after the approval time has expired": '審査時間を超えた場合の操作',
    "temporarily saved time": "一時保存時間",
    apply: '有効にする',
    'serial number': '番号',
    'the approver cannot be empty': '審査人は空にできません',
    "email save temporarily": "メール一時保存",
    "recipient/domain": "送信先／ドメイン",
    "strategy effective time": "戦略発効時間",
    "delete successfully": "削除完了",
    "sender": "差出人",
    "Mail domain lists support only 1-100 mail domain objects": "メールドメインリストは1－100メールドメインオブジェクトしかサポートしません。",
    '64 bytes at most': '最大64バイト',
    "Mail address lists support only 1-100 mail address objects": "メールアドレスリストは1－100メールアドレスオブジェクトしかサポートしません。",
    "hour": "時間",
    "define file types": "ファイルタイプを定義する",
    "edit attachment sending encryption": "添付ファイル送信暗号化を編集します",
    "add new attachment sending encryption": "添付ファイル送信暗号化を新規作成します",
    "add new email staging": "メールステージングを新規作成します",
    "edit email staging": "メールステージングを編集します",
    "recipient/recipirnt domain": "送信先/送信先ドメイン",
    "effective approval time": "有効な審査時間",
    "release": "リリース",
    "bounce the email and notice the sender": "メールを差し戻して差出人を知らせます",
    "edit the email approval": "メール審査を編集します",
    "add new email approval": "メール審査を新規作成します",
    "object name": "オブジェクトネーム",
    'object management': 'オブジェクト管理',
    'security policy': 'セキュリティ戦略',
    'email address object': 'メールアドレスオブジェクト',
    'mail domain list': 'メールドメインリスト',
    'mail domain list cannot be empty': 'メールドメインリストは空きにできません',
    'domain object': 'ドメインオブジェクト',
    'address object': 'アドレスオブジェクト',
    'email domain object': 'メールドメインオブジェクト',
    'Email attachment sending encryption': 'メール添付ファイル送信暗号化',
    'Email save temporarily': 'メール一時保存',
    'Email approve': 'メール審査',
    'time object': '時間オブジェクト',
    'file type object': 'ファイルタイプオブジェクト',
    "member": "メンバー",
    "quote": "引用",
    "add new email address object": "メールアドレスオブジェクトを新規作成します",
    "edit email address object": "メールアドレスオブジェクトを編集します",
    "email address list": "メールアドレスリスト",
    "description": "記述",
    "support for entering multiple email addresses separated by": "複数のメールアドレスを入力できます。「,」で仕切ってください",
    "add new email domain object": "メールドメインオブジェクトを新規作成します",
    "edit email domain object": "メールドメインオブジェクトを 編集します",
    "start time": "開始時刻",
    "end time": "終了時間",
    "sender+recipient": "差出人+送信先",
    "recipient": "送信先",
    domain: "ドメイン",
    'external domain': "外部ドメイン",
    'matching condition': '差出人マッチ条件',
    'recipient matching': '送信先マッチ条件',
    include: "を含む",
    "type of attachment that needs to be encrypted": "暗号化させる添付ファイルタイプ ",
    "edit email attachment sending encryption": "添付ファイル送信暗号化を編集します",
    "add new email attachment sending encryption": "添付ファイル送信暗号化を新規作成します",
    "password recipient": "パスワード受信者",
    'mail domain': 'メールドメイン',
    'mail object': 'メールオブジェクト',
    'Please select the sender message': "差出人の情報を選択してください",
    'The domain name you filled in is a non-independent domain name, please select other options':'入力したドメイン名は独立していないドメイン名です。他のオプションを選択してください'
    
  }
}
