import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './vuex/store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/iconfont.css'
import '@/assets/css/style.css'

Vue.use(ElementUI);
axios.defaults.withCredentials = true;
// axios.defaults.headers.post['Content-Type'] = 'application/json';
Vue.config.debug = true;
const reg = /(localhost)/i
const testA = /(10.51.15.214)/i;

// 添加请求拦截器
axios.interceptors.request.use(request => {
  // store.commit("UPDATE_Loading", true);
  return request
}, error => {
  return Promise.reject(error);
})
// 添加响应拦截器
axios.interceptors.response.use(response => {
  // store.commit("UPDATE_Loading", false);
  // if (response.data.code === 2001206 || response.data.code === 2001207) {
  // 	//登录过期
  // 	location.href = '/login'
  // } else if (response.data.code === 2001208) {
  // 	// 请先验证邮箱
  // 	location.href = "/verification"
  // }

  return response
}, error => {
  return Promise.reject(error);
});

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
// function postJson(url, params) {
//   return axios.post(url, params, {
//     headers: {
//       "Content-Type": 'application/json;charset=UTF-8'
//     },
//     transformRequest: [function (params) {
//       return JSON.stringify(params);
//     }]
//   })

// };

Vue.config.productionTip = false;
Vue.prototype.axios = axios;
/** 懒加载图片 */
import VueSrc from './directives/src/index.js'
Vue.directive('src', VueSrc)
// i18n
import i18n from './i18n/i18n'
//路由加载之前的事件拦截
router.beforeEach((to, from, next) => {
  next();
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
